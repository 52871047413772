import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './clients.scss';
import Carousel from '../../views/index-sections/Carousel.js';
import useWindowDimensions from '../../utils/window-width';

const Clients = (props) => {
  const { clients, title } = props;
  return (
    <>
      <h2 className='title underline'>{title}</h2>
      <p className='pb-3 description'>{props.text}</p>

      <Container fluid>
        <div className='clients'>
          <Row>
            <Col className='ml-auto mr-auto' sm='12'></Col>
            <Col className='ml-auto mr-auto' md='12' sm='12'>
              <Carousel
                imagesHeight={'400px'}
                key={'Caroussel_clients'}
                itemsPerSlide={
                  useWindowDimensions().width < 768
                    ? 1
                    : clients.length >= 3
                    ? 3
                    : clients.length
                }
                items={clients}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default Clients;
