import React from 'react';
import Helmet from 'react-helmet';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';
// core components
import MainNavbar from '../components/Navbars/MainNavbar.js';
import FcsValues from '../components/sections/FcsValues';
import Services from '../components/sections/Services';
import Clients from '../components/sections/Clients';
import News from '../components/sections/News';
import FeaturedProducts from '../components/sections/FeaturedProducts';
import ContactForm from '../components/sections/ContactForm';
import LandingPageHeader from '../components/Headers/LandingPage/LandingPageHeader.js';
import DefaultFooter from '../components/Footers/DefaultFooter.js';
import { scrollTo } from '../components/Helpers/scroll-helper';

import '../assets/css/bootstrap.min.css';
import '../assets/scss/now-ui-kit.scss?v=1.4.0';
import '../assets/demo/demo.css?v=1.4.0';
import '../assets/demo/nucleo-icons-page-styles.css?v=1.4.0';
import logoImg from './../assets/img/logo_paulo_lourenco_cores_small.png';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faHandshake,
  faUserTie,
  faAward,
  faHome,
  faPhone,
  faUserCircle,
  faEnvelope,
  faUsers,
  faNewspaper,
  faMedal,
  faInfoCircle,
  faEnvelopeOpenText,
  faHardHat,
  faTools,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';

import { dom } from '@fortawesome/fontawesome-svg-core';

dom.watch();

library.add(
  faFacebook,
  faLinkedin,
  fab,
  faArrowRight,
  faArrowLeft,
  faCheckSquare,
  faCoffee,
  faChevronRight,
  faChevronLeft,
  faHandshake,
  faUserTie,
  faAward,
  faHome,
  faPhone,
  faUserCircle,
  faEnvelope,
  faUsers,
  faNewspaper,
  faMedal,
  faInfoCircle,
  faEnvelopeOpenText,
  faHardHat,
  faTools,
  faClipboardList
);

function LandingPage(props) {
  // const pages = props.data.allMongodbGatsbyPages.edges;
  const { content } = useStaticQuery(pageQuery).mongodbCloserDevCmsPages;
  console.log(content);

  React.useEffect(() => {
    if (props.location.hash && props.location.hash !== '') {
      const data = props.location.hash.replace('#', '').split('|');
      data.length > 1 &&
        scrollTo(
          data[1] === 'id'
            ? document.getElementById(data[0])
            : document.getElementsByClassName(data[0])[0]
        );
    }
  }, [content]);

  return (
    <>
      <Helmet
        title={content.seo.titleSeo}
        meta={[
          {
            name: 'description',
            content: content.seo.description,
          },
          {
            name: 'keywords',
            content: content.seo.keywords,
          },
          { name: 'image', content: logoImg },
          { name: 'og:image', content: logoImg },
        ]}
      >
        <html lang='pt' />
      </Helmet>
      <MainNavbar socialLinks={content.contacts.socialLinks} />
      <div className='wrapper' id='top'>
        <LandingPageHeader
          socialLinks={content.contacts.socialLinks}
          title={content.title}
          subtitle={'SOLUÇÕES DE SISTEMAS DE SEGURANÇA'}
        />
        <Container>
          <div className='section section-about-us' id='aboutUs'>
            <Row>
              <Col className='ml-auto mr-auto' md='12'>
                {/* <h2 className='title underline'>{content.aboutUs.title}</h2> */}
                <Row className='section'>
                  {/* <Col lg='6' md='12'>
                    <div
                      className='rounded img-raised'
                      style={{
                        height: '400px',
                        backgroundImage:
                          'url(' +
                          content.aboutUs.img +
                          // require('../assets/img/enterprise-environment.jpeg') +
                          ')',
                      }}
                    ></div>
                  </Col> */}
                  <Col className='flex column center' lg='12' md='12'>
                    <p
                      className='description pt-3'
                      style={{ 'text-align': 'justify' }}
                    >
                      {
                        content.aboutUs.text
                        /* According to the National Oceanic and Atmospheric
                      Administration, Ted, Scambos, NSIDClead scentist, puts the
                      potentially record low maximum sea ice extent tihs year
                      down to low ice extent in the Pacific and a late drop in
                      ice extent in the Barents Sea. According to the National
                      Oceanic and Atmospheric Administration, Ted, Scambos,
                      NSIDClead scentist, puts the potentially record low
                      maximum sea ice extent tihs year down to low ice extent in
                      the Pacific and a late drop in ice extent in the Barents
                      Sea. According to the National Oceanic and Atmospheric
                      Administration, Ted, Scambos, NSIDClead scentist, puts the
                      potentially record low maximum sea ice extent tihs year
                      down to low ice extent in the Pacific and a late drop in
                      ice extent in the Barents Sea. */
                      }
                    </p>
                  </Col>
                </Row>

                <Services
                  advantages={content.services.items}
                  title={content.services.title}
                />
                <br />
                <br />
                <br />
                {/* <hr /> */}
                <FcsValues
                  advantages={content.fcs_Values.advantages}
                  title={content.fcs_Values.title}
                />

                <br />
                <br />
                <br />
                {/* <hr /> */}
              </Col>
            </Row>
          </div>
          <div className='section' id='Clients'>
            <Clients
              clients={content.aboutUs.clientsSection.clients}
              title={content.aboutUs.clientsSection.title}
              text={content.aboutUs.clientsSection.text}
            />
            <br />
            <br />
            {/* <hr /> */}
            <br />
          </div>
          <div className='section' id='news'>
            <Row>
              <Col className='ml-auto mr-auto' md='12'>
                <News
                  news={content.news_section.newsList}
                  title={content.news_section.title}
                  text={content.news_section.text}
                />
                {/* <hr /> */}
                <br />
              </Col>
            </Row>
          </div>
          <div className='section' id='featuredProducts'>
            <FeaturedProducts
              products={content.featuredProductsInLandingPage.productsList}
              title={content.featuredProductsInLandingPage.title}
              text={content.featuredProductsInLandingPage.text}
            ></FeaturedProducts>
          </div>
        </Container>
        {/* <div className='section section-team text-center' id='team'>
          <Container>
            <h2 className='title underline'>A nossa equipa</h2>
            <div className='team'>
              <Row>
                <Col md='4'>
                  <div className='team-player'>
                    <img
                      alt='...'
                      className='rounded-circle img-fluid img-raised'
                      src={require('../assets/img/avatar.jpg')}
                    ></img>
                    <h4 className='title'>Romina Hadid</h4>
                    <p className='category text-info'>Model</p>
                    <p className='description'>
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{' '}
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        links
                      </a>{' '}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-twitter'></i>
                    </Button>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-instagram'></i>
                    </Button>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-facebook-square'></i>
                    </Button>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='team-player'>
                    <img
                      alt='...'
                      className='rounded-circle img-fluid img-raised'
                      src={require('../assets/img/ryan.jpg')}
                    ></img>
                    <h4 className='title'>Ryan Tompson</h4>
                    <p className='category text-info'>Designer</p>
                    <p className='description'>
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{' '}
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        links
                      </a>{' '}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-twitter'></i>
                    </Button>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-linkedin'></i>
                    </Button>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='team-player'>
                    <img
                      alt='...'
                      className='rounded-circle img-fluid img-raised'
                      src={require('../assets/img/eva.jpg')}
                    ></img>
                    <h4 className='title'>Eva Jenner</h4>
                    <p className='category text-info'>Fashion</p>
                    <p className='description'>
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{' '}
                      <a href='#pablo' onClick={(e) => e.preventDefault()}>
                        links
                      </a>{' '}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-google-plus'></i>
                    </Button>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-youtube'></i>
                    </Button>
                    <Button
                      className='btn-icon btn-round'
                      color='info'
                      href='#pablo'
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className='fab fa-twitter'></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div> */}
        <ContactForm key='contact-form' {...content.contactUs}></ContactForm>
        <DefaultFooter
          phones={content.contacts.phones}
          addresses={[...content.contacts.addresses]}
        />
      </div>
    </>
  );
}

export default LandingPage;

export const pageQuery = graphql`
  query {
    mongodbCloserDevCmsPages(mongodb_id: { eq: "5f8f2295ea3e4769348cabc0" }) {
      content {
        title
        aboutUs {
          text
          img
          clientsSection {
            title
            text
            clients {
              img
              altText
              title
            }
          }
        }
        fcs_Values {
          title
          advantages {
            text
            title
            icon
          }
        }
        services {
          title
          items {
            text
            title
            icon
          }
        }
        news_section {
          title
          text
          newsList {
            imageUrl
            text
            title
          }
        }
        # news {
        #   title
        #   newsList {
        #     imageUrl
        #     text
        #     title
        #   }
        # }
        featuredProductsInLandingPage {
          title
          text
          productsList {
            img
            altText
            caption
            title
            redirect
          }
        }
        contactUs {
          title
          text
          emailToSendForm
        }
        contacts {
          phones
          addresses {
            label
            link
          }
          socialLinks {
            facebook {
              icon
              url
            }
            linkedin {
              icon
              url
            }
          }
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
  }
`;
