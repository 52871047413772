import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import './fcValues.scss';

const Services = (props) => {
  const { advantages, title } = props;
  const [showIcons, setShowIcons] = React.useState(false);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      const triggerheight =
        document.documentElement.clientWidth < 768 ? 1400 : 500;
      if (
        document.documentElement.scrollTop > triggerheight - 1 ||
        document.body.scrollTop > triggerheight - 1
      ) {
        setShowIcons(true);
      } else if (
        document.documentElement.scrollTop < triggerheight ||
        document.body.scrollTop < triggerheight
      ) {
        setShowIcons(false);
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <>
      <h3 className='title underline'>{title}</h3>
      <div className='section section-team text-center'>
        <Container>
          <div className='team'>
            <Row>
              {advantages.map((advantage) => {
                return (
                  <Col key={advantage.title + advantage.icon + Math.random().toString(36).substr(2, 9).toString()} md='4'>
                    <div className='team-player' style={{minHeight:'400px'}}>
                      {showIcons ? (
                        <StyleRoot>
                          <div
                            key={
                              advantage.title + advantage.icon + 'wrapper-icon'
                            }
                            className='wrapper-icon'
                            style={{
                              animation: 'x 1s',
                              animationName: Radium.keyframes(
                                fadeIn,
                                'fadeIn'
                              ),
                            }}
                          >
                            <i className={advantage.icon}></i>
                          </div>{' '}
                          <div
                            style={{
                              animation: 'x 1s',
                              animationName: Radium.keyframes(
                                fadeIn,
                                'fadeIn'
                              ),
                            }}
                          >
                            <h4 className='title'>{advantage.title}</h4>
                            {/* <p className='category text-info'>{advantage.title}</p> */}
                            <p className='description'>{advantage.text}</p>
                          </div>
                        </StyleRoot>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Services;
