import React from 'react';
import {
  Row,
  Col,
  Button,
  Container,
  Input,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Form,
  CardFooter,
  Card,
  CardHeader,
  CardTitle,
  FormGroup,
  Label,
  CardBody,
} from 'reactstrap';
import useFetchPost from '../../http/post-request';
import './contactForm.scss';
// import './news.scss';
const emailServiceUrl = 'https://emailservice.closerdev.com/api/sendemail';

const ContactForm = (props) => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState('');
  const [formMessage, setformMessage] = React.useState('');

  const { title, text, emailToSendForm, style } = props;

  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  const sendemail = () => {
    setLoading(true);
    useFetchPost
      .call(this, emailServiceUrl, {
        mailTo: emailToSendForm,
        subject: 'Client contact',
        fromName: name,
        message,
        email,
        nome: name,
      })
      .then((res) => {
        setLoading(false);

        setformMessage('Mensagem enviada!');
      })
      .catch((err) => {
        setLoading(false);
        setformMessage('Aconteceu algo de errado...');
        console.log(err);
      });

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.gtag && window.gtag('event', 'conversion', {
      'send_to': 'AW-10782010394/1nkUCNWE6ZoYEJrYoZUo',
      'event_callback': ()=>{}
  });
    sendemail.call(this);
  };

  // setLoading(false)
  return (
    <>
      <div className='section' id='contact'>
        <div
          className='flex center news-letter--wrapper'
          style={{ alignItems: 'center' }}
        >
          <div
            className='flex center'
            style={{
              color: '#f47244',
              fontSize: '80px',
              border: '4px solid #f47244',
              borderRadius: '50%',
              width: '150px',
              height: '150px',
              flexDirection: 'column',
              alignItems: 'center',
              // margin: 'auto',
              marginBottom: '20px',
              marginRight: '20px',
            }}
            key={Math.random().toString(36).substr(2, 9).toString()}
          >
            <i class='fas fa-envelope-open-text'></i>
          </div>
          <div
            className='flex center'
            style={{ height: '90px' }}
            key={Math.random().toString(36).substr(2, 9).toString()}
          >
            <Button
              href='https://mline.hubspotpagebuilder.com/subscricao-email-marketing'
              target='_blank'
              variant='outline-primary'
              color='black'
              style={{
                fontSize: 'large',
                backgroundColor: '#ffffff',
                color: '#f47244',
                border: '3px solid #f47244',
                marginBottom: '20px',
              }}
              size='lg'
              key={Math.random().toString(36).substr(2, 9).toString()}
            >
              <i id="sadffa" key='newsletter-icon' className='fas fa-newspaper mr-2'></i>{' '}
              Subscrever newsletter
            </Button>
          </div>
        </div>
        <br />
        <Container>
          <Row>
            <Col sm='0' md='3'></Col>
            <Col sm='12' md='6'>
              <Card
                className='card-catalog mx-auto'
                data-background-color='blue'
              >
                <Form onSubmit={handleSubmit} className='form'>
                  <CardHeader className='text-center'>
                    <CardTitle className='title-up' tag='h3'>
                      {title}
                    </CardTitle>
                    <p
                      style={{ 'text-align': 'center' }}
                      className='description'
                    >
                      {text}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        'input-lg' + (firstFocus ? ' input-group-focus' : '')
                      }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText
                          key={Math.random()
                            .toString(36)
                            .substr(2, 9)
                            .toString()}
                        >
                          <i className='fas fa-user-circle'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Nome'
                        type='text'
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        'input-lg' + (lastFocus ? ' input-group-focus' : '')
                      }
                    >
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText
                          key={Math.random()
                            .toString(36)
                            .substr(2, 9)
                            .toString()}
                        >
                          <i className='fas fa-envelope'></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='email'
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      ></Input>
                    </InputGroup>
                    <div className='textarea-container'>
                      <Input
                        cols='80'
                        name='name'
                        placeholder='Escreva sua mensagem'
                        rows='4'
                        type='textarea'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        required
                      ></Input>
                    </div>
                    <h5>
                      <b>
                        {formMessage && formMessage !== '' ? formMessage : ''}
                      </b>
                    </h5>
                  </CardBody>
                  <CardFooter className='text-center'>
                    {!loading ? (
                      <Button
                        className='btn-neutral btn-round'
                        color='info'
                        size='lg'
                        type='submit'
                      >
                        Submeter
                      </Button>
                    ) : (
                      <div className='loader white'>Loading...</div>
                    )}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default ContactForm;
