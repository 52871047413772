import React from 'react';
import { Row, Col } from 'reactstrap';
// import './news.scss';
import Carousel from '../../views/index-sections/Carousel.js';

const FeaturedProducts = (props) => {
  const { products } = props;
  return (
    <>
      <h2 className='title underline'>
        {props.title}
      </h2>
      <p className='pb-3 description'>{props.text}</p>
      <Row>
        <Col className='ml-auto mr-auto' md='10' sm='12'>
          <Carousel items={products} itemsPerSlide={3} imagesHeight={'300px'} />
          {/* <hr /> */}
          <br />
        </Col>
      </Row>
    </>
  );
};
export default FeaturedProducts;
