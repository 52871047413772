import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import './news.scss';
const News = (props) => {
  const { news } = props;
  debugger
  return (
    <>
      <h2 className='title underline'>{props.title}</h2>
      <p className='pb-3 description'>{props.text}</p>
      <Row className="mt-3">
        {news.reverse().map((entry) => {
          return (
            <Col
              key={entry.imageUrl + entry.title}
              className='ml-auto mr-auto mb-3'
              md='12'
              lg='6'
            >
              <Card style={{ 'height': '100%', 'margin-bottom': '10px' }}>
                <CardHeader>
                  <LazyBackground src={ entry.imageUrl} />
                </CardHeader>
                <CardBody style={{ 'height': '100%' }}>
                  <h3>{entry.title}</h3>
                  <p>{entry.text}</p>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

class LazyBackground extends React.Component {
  state = { src: null };

  componentDidMount() {
    const { src } = this.props;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
    return <div className='img-news' {...this.props} style={{ backgroundImage: `url(${this.state.src || this.props.placeholder})` }} />;
  }
}
export default News;
